/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { storeCategoryTree } from 'Actions'
import data from './component'

const mapDispatchToProps = dispatch => ({
  storeCategoryTree: bindActionCreators(storeCategoryTree, dispatch)
})

const CategoryTree = (props) => {
  useEffect(() => {
    props.storeCategoryTree(data)
  }, [])
  return null
}

export default connect(null, mapDispatchToProps)(CategoryTree)
